import { graphql, useFragment } from "react-relay";
import { TextQuestion } from "@olivahealth/oli-ui";
import { TextRenderer_question$key } from "./__generated__/TextRenderer_question.graphql";
import { SurveyAnswerInput } from "./__generated__/useNativeSurveyCreateMutation.graphql";

interface Props {
  initialAnswer: SurveyAnswerInput | null;
  isFullPage: boolean;
  onTextChange: (value: string) => void;
  question: TextRenderer_question$key;
}

const TextRendererFragment = graphql`
  fragment TextRenderer_question on Question {
    id
    options {
      label
      sublabel
      value
    }
  }
`;

export default function TextRenderer({
  initialAnswer,
  isFullPage,
  onTextChange,
  question,
}: Props): JSX.Element {
  const data = useFragment<TextRenderer_question$key>(
    TextRendererFragment,
    question,
  );

  const maxRowsVisible = 3;
  const config = data.options.find((option) => option.label === "placeholder");
  const initialValue = initialAnswer?.chosenOption.value ?? "";

  return (
    <TextQuestion
      initialValue={initialValue}
      placeholder={config?.value ?? ""}
      onChange={onTextChange}
      notice={config?.sublabel ?? ""}
      question={{
        id: data.id,
        position: 0,
        type: "text",
      }}
      minRows={isFullPage ? maxRowsVisible : 1}
      maxRows={maxRowsVisible}
    />
  );
}
