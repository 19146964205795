import { SubmissionStatus } from "@prisma/mongoClient";

export const CompleteSubmissionStatus = [
  SubmissionStatus.CHOOSING_MATCH,
  SubmissionStatus.COMPLETED,
  SubmissionStatus.LIFE_RISK,
  SubmissionStatus.MATCHED,
  SubmissionStatus.NEEDS_MATCHING,
] as SubmissionStatus[];

export const IncompleteSubmissionStatus = [
  SubmissionStatus.DELETED,
  SubmissionStatus.PENDING,
] as SubmissionStatus[];

export default SubmissionStatus;
