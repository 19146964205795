/**
 * A utility function to await a specific time.
 * @example
 * async () => {
 *   await delay(5000);
 *   console.log("Waited 5s");
 *   await delay(5000);
 *   console.log("Waited an additional 5s");
 * };
 */
export default function delay(delayMilliseconds: number) {
  return new Promise((resolvePromise) =>
    setTimeout(resolvePromise, delayMilliseconds),
  );
}
