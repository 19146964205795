/**
 * @generated SignedSource<<1ea8f8410bd81f2f2ccc41daa327022e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NativeSurveyQuestionType = "HEADER" | "MULTIPLE_CHOICES" | "SINGLE_CHOICE" | "TEXT" | "TOPICS";
export type SurveyAnswerInput = {
  chosenOption: OptionInput;
  id: string;
  type: NativeSurveyQuestionType;
};
export type OptionInput = {
  label: string;
  score: number;
  subValue?: string | null | undefined;
  sublabel?: string | null | undefined;
  value: string;
};
export type useNativeSurveyCompleteMutation$variables = {
  answers: ReadonlyArray<SurveyAnswerInput>;
  submissionId: string;
};
export type useNativeSurveyCompleteMutation$data = {
  readonly completeSurveySubmission: {
    readonly __typename: "SurveySubmissionCompleted";
    readonly success: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useNativeSurveyCompleteMutation = {
  response: useNativeSurveyCompleteMutation$data;
  variables: useNativeSurveyCompleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "answers"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "submissionId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "answers",
        "variableName": "answers"
      },
      {
        "kind": "Variable",
        "name": "submissionId",
        "variableName": "submissionId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "completeSurveySubmission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "type": "SurveySubmissionCompleted",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useNativeSurveyCompleteMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useNativeSurveyCompleteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "99b7366e2d81b3b5f02df5a4c6fc17a6",
    "id": null,
    "metadata": {},
    "name": "useNativeSurveyCompleteMutation",
    "operationKind": "mutation",
    "text": "mutation useNativeSurveyCompleteMutation(\n  $submissionId: ID!\n  $answers: [SurveyAnswerInput!]!\n) {\n  completeSurveySubmission(submissionId: $submissionId, answers: $answers) {\n    __typename\n    ... on SurveySubmissionCompleted {\n      success\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "54a21826607d4e4a14048c8b90ded86a";

export default node;
