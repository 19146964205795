import { Button, Logo, Text } from "@olivahealth/oli-ui";
import { useRouter } from "next/router";
import { useIntercom } from "react-use-intercom";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import * as s from "./styles";

export default function NotFoundPage(): JSX.Element {
  const { push } = useRouter();
  const { t } = useTranslation("common", { keyPrefix: "notFoundPage" });
  const { show } = useIntercom();

  return (
    <div className="h-full w-full lg:p-8">
      <div className={s.innerWrapper}>
        <span className="absolute left-8 top-8">
          <Logo variant="full" color="white" height={24} />
        </span>
        <div className={s.textRowWrapper}>
          <div className={s.leftColumnWrapper}>
            <Text component="h1" variant="h1" color="white">
              {t("title")}
            </Text>
            <Text component="p" variant="h4" color="white">
              {t("description")}
            </Text>
            <span className="flex gap-x-3">
              <Button onClick={show}>{t("buttonLetUsHelpYouText")}</Button>
              <Button variant="tertiaryInverse" onClick={() => push("/")}>
                {t("buttonText")}
              </Button>
            </span>
          </div>
          <div className={s.imageWrapper}>
            <Image
              src="/img/img_meditating_man.png"
              alt="meditating man"
              fill
              sizes="50vw"
              style={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
