import { graphql, useLazyLoadQuery, useMutation } from "react-relay";
import { useEffect, useReducer } from "react";
import delay from "@olivahealth/graphql-server/src/domain/utils/delay";
import QuestionType from "@olivahealth/graphql-server/src/domain/value-objects/QuestionType";
import SubmissionStatus from "@olivahealth/graphql-server/src/domain/value-objects/TriagingSubmissionStatus";
import { toast } from "@olivahealth/oli-ui";
import { DEBOUNCE_TEXT_TIME } from "@olivahealth/oli-ui/src/organisms/TextQuestion";
import useDebounce from "@olivahealth/utils/reactHooks/useDebounce";

import { OlivaHook } from "../../../hooks/OlivaHook";
import useTranslation from "../../../hooks/useTranslation";
import {
  EventNames,
  useAmplitude,
} from "../../../services/contexts/AmplitudeContext";
import {
  useNativeSurveyQuery$data as IQueryData,
  useNativeSurveyQuery as IQuery,
  NativeSurveyQuestionType,
  NativeSurveyTitle,
} from "./__generated__/useNativeSurveyQuery.graphql";
import { useNativeSurvey_options$data as OptionsData } from "./__generated__/useNativeSurvey_options.graphql";
import {
  SurveyAnswerInput,
  SurveySubmissionMetadataInput,
  useNativeSurveyCreateMutation,
} from "./__generated__/useNativeSurveyCreateMutation.graphql";
import { useNativeSurveyCompleteMutation } from "./__generated__/useNativeSurveyCompleteMutation.graphql";

type TrackableAmplitudeEvents = Array<{
  name: EventNames;
  eventProperties?: Record<string, any>;
}>;

export interface SurveyFlowToEventsMapping {
  onSurveyOpened?: TrackableAmplitudeEvents;
  onQuestionViewed?: {
    [key in string]: TrackableAmplitudeEvents;
  };
  onQuestionAnswered?: {
    [key in string]: TrackableAmplitudeEvents;
  };
  onSurveyCompleted?: TrackableAmplitudeEvents;
}

export type NativeSurveyAnswers = Map<string, SurveyAnswerInput>;

export interface NativeSurveyFormInitialState {
  currentQuestionIndex: number;
  initialAnswers: NativeSurveyAnswers;
}

export interface OnAnswersChanged {
  (answers: NativeSurveyAnswers, currentQuestionIndex: number): void;
}

enum NativeSurveyActionKind {
  ANSWER_UPDATED = "ANSWER_UPDATED",
  NEXT_QUESTION = "NEXT_QUESTION",
  PREVIOUS_QUESTION = "PREVIOUS_QUESTION",
}

interface NativeSurveyActions {
  type: NativeSurveyActionKind;
  payload: IQueryData;
  answer?: SurveyAnswerInput;
  onAnswerConfirmed?: () => void;
}

type NativeSurveyState = Omit<UseNativeSurveyData, "isSubmitting">;

interface Props {
  formInitialState?: NativeSurveyFormInitialState;
  id?: string;
  submissionId?: string | null;
  submissionStatus?: SubmissionStatus;
  surveyFlowToEventsMapping: SurveyFlowToEventsMapping;
  trackAnswers: boolean;
  title?: NativeSurveyTitle;
  onAnswersChanged?: OnAnswersChanged;
  onSubmitSuccess?: (answers: NativeSurveyAnswers) => void;
  metadata: SurveySubmissionMetadataInput;
}

interface UseNativeSurveyData {
  isSubmitting: boolean;
  answers: NativeSurveyAnswers;
  currentAnswer: SurveyAnswerInput | null;
  currentQuestionIndex: number;
  lastActionType: NativeSurveyActionKind | null;
  numberOfQuestions: number;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  currentQuestionData: {
    id: string;
    header?: string | null;
    title: string;
    subtitle?: string | null;
    type: NativeSurveyQuestionType;
    needsConfirmation?: boolean | null;
    notice?: string | null;
    preSelectedOptionValue?: string | null;
    options: OptionsData["options"];
  };
}

interface UseNativeSurveyEffects {
  handleTextChange: (value: string) => void;
  handleOptionChosen: (optionValue: string) => void;
  handleSubValueChange: (optionValue: string, subValue: string) => void;
  navigateToPreviousQuestion: () => void;
  navigateToNextQuestion: () => void;
  submit: () => void;
}

type UseNativeSurvey = OlivaHook<UseNativeSurveyData, UseNativeSurveyEffects>;

graphql`
  fragment useNativeSurvey_options on Question {
    options {
      label
      sublabel
      subValue
      score
      value
    }
  }
`;

const NativeSurveyQuery = graphql`
  query useNativeSurveyQuery($id: ID, $title: NativeSurveyTitle) {
    nativeSurvey(id: $id, title: $title) {
      __typename
      ... on NativeSurvey {
        id
        questions {
          id
          groupId
          header
          logic {
            requiredAnswers {
              questionId
              answerIds
            }
          }
          needsConfirmation
          notice
          preSelectedOptionValue
          title
          subtitle
          type
          ...useNativeSurvey_options @relay(mask: false)
          ...SingleChoiceRenderer_question
          ...TextRenderer_question
        }
      }
    }
  }
`;

const NativeSurveyCreateMutation = graphql`
  mutation useNativeSurveyCreateMutation(
    $surveyId: ID!
    $metadata: SurveySubmissionMetadataInput!
    $answers: [SurveyAnswerInput!]!
    $status: SurveySubmissionStatusEnum
  ) {
    createSurveySubmission(
      surveyId: $surveyId
      metadata: $metadata
      answers: $answers
      status: $status
    ) {
      __typename
      ... on SurveySubmissionCreated {
        id
      }
    }
  }
`;

const NativeSurveyCompleteMutation = graphql`
  mutation useNativeSurveyCompleteMutation(
    $submissionId: ID!
    $answers: [SurveyAnswerInput!]!
  ) {
    completeSurveySubmission(submissionId: $submissionId, answers: $answers) {
      __typename
      ... on SurveySubmissionCompleted {
        success
      }
    }
  }
`;

export default function useNativeSurvey({
  formInitialState,
  id,
  submissionId,
  submissionStatus,
  surveyFlowToEventsMapping,
  title,
  trackAnswers,
  metadata,
  onAnswersChanged,
  onSubmitSuccess,
}: Props): UseNativeSurvey {
  const { trackEvent } = useAmplitude();
  const { t } = useTranslation("surveys");
  const data = useLazyLoadQuery<IQuery>(NativeSurveyQuery, {
    id,
    title,
  });
  const [submitSurveySubmissionCreate, creatingSubmission] =
    useMutation<useNativeSurveyCreateMutation>(NativeSurveyCreateMutation);
  const [submitSurveySubmissionComplete, completingSubmission] =
    useMutation<useNativeSurveyCompleteMutation>(NativeSurveyCompleteMutation);

  const [state, dispatch] = useReducer(
    nativeSurveyReducer,
    { nativeSurvey: data.nativeSurvey, formInitialState },
    getInitialState,
  );

  // Fixes a weird issue where some answers are not updated when the user quickly navigates to the next question
  const debouncedAnswers = useDebounce(state.answers, 300);

  const isLoadingSurveySuccessful =
    data.nativeSurvey.__typename === "NativeSurvey";

  const onQuestionIndexChange = () => {
    const questionId = state.currentQuestionData.id;
    const events: TrackableAmplitudeEvents =
      surveyFlowToEventsMapping.onQuestionViewed?.[questionId] ?? [];

    events.forEach((event) =>
      trackEvent(event.name, {
        ...event.eventProperties,
        questionNumber: state.currentQuestionIndex,
        questionId,
      }),
    );
  };

  useEffect(() => {
    if (state.lastActionType !== NativeSurveyActionKind.ANSWER_UPDATED) {
      return;
    }

    onAnswersChanged?.(state.answers, state.currentQuestionIndex);

    const { currentQuestionData } = state;
    if (
      currentQuestionData.type === QuestionType.SINGLE_CHOICE &&
      !currentQuestionData.needsConfirmation &&
      !state.isLastQuestion
    ) {
      navigateToNextQuestion();
    }
  }, [debouncedAnswers]);

  useEffect(() => {
    const onSurveyOpenedEvents: TrackableAmplitudeEvents =
      surveyFlowToEventsMapping.onSurveyOpened ?? [];

    onSurveyOpenedEvents.forEach((event) =>
      trackEvent(event.name, {
        ...event.eventProperties,
        submissionId,
      }),
    );
  }, []);

  useEffect(() => {
    onQuestionIndexChange();

    onAnswersChanged?.(state.answers, state.currentQuestionIndex);
  }, [state.currentQuestionIndex]);

  const updateAnswer = (answer: SurveyAnswerInput) => {
    dispatch({
      type: NativeSurveyActionKind.ANSWER_UPDATED,
      payload: data,
      answer,
    });
  };

  const handleTextChange = (value: string) => {
    const { id } = state.currentQuestionData;
    const surveyAnswerInput: SurveyAnswerInput = {
      chosenOption: {
        label: "",
        score: 0,
        value,
      },
      id,
      type: QuestionType.TEXT,
    };

    updateAnswer(surveyAnswerInput);
  };

  const handleSubValueChange = (optionValue: string, subValue: string) => {
    const { id, options } = state.currentQuestionData;
    const chosenOption = options?.find(({ value }) => value === optionValue);
    const surveyAnswerInput: SurveyAnswerInput = {
      chosenOption: {
        ...chosenOption,
        label: chosenOption?.label ?? "",
        score: chosenOption?.score ?? 0,
        value: chosenOption?.value ?? "",
        subValue,
      },
      id,
      type: QuestionType.SINGLE_CHOICE,
    };

    updateAnswer(surveyAnswerInput);
  };

  const handleOptionChosen = (optionValue: string) => {
    const { id, options, type } = state.currentQuestionData;
    const chosenOption = options?.find(({ value }) => value === optionValue);

    if (chosenOption) {
      const surveyAnswerInput: SurveyAnswerInput = {
        chosenOption,
        id,
        type,
      };

      updateAnswer(surveyAnswerInput);
    }
  };

  const navigateToPreviousQuestion = () => {
    navigateToQuestion(
      dispatch,
      NativeSurveyActionKind.PREVIOUS_QUESTION,
      data,
    );
  };

  const navigateToNextQuestion = () => {
    navigateToQuestion(
      dispatch,
      NativeSurveyActionKind.NEXT_QUESTION,
      data,
      onAnswerConfirmed,
    );
  };

  const onAnswerConfirmed = () => {
    const questionId = state.currentQuestionData.id;
    const events: TrackableAmplitudeEvents =
      surveyFlowToEventsMapping.onQuestionAnswered?.[questionId] ?? [];

    events.forEach((event) =>
      trackEvent(event.name, {
        ...event.eventProperties,
        questionNumber: state.currentQuestionIndex,
        questionId,
        answerValue: trackAnswers
          ? state.currentAnswer?.chosenOption.value
          : undefined,
      }),
    );
  };

  const onSurveyCompleted = (finalSubmissionId: string) => {
    const onCompletedEvents: TrackableAmplitudeEvents =
      surveyFlowToEventsMapping.onSurveyCompleted ?? [];

    onCompletedEvents.forEach((event) =>
      trackEvent(event.name, {
        ...event.eventProperties,
        submissionId: finalSubmissionId,
      }),
    );

    onSubmitSuccess?.(state.answers);
  };

  const submit = () => {
    if (!isLoadingSurveySuccessful) {
      return;
    }

    onAnswerConfirmed();

    // Eventually re-order answers to follow the survey questions original order
    const answersValues = data.nativeSurvey.questions
      .reduce((acc, question) => {
        const answer = state.answers.get(question.id);

        return answer ? acc.concat(answer) : acc;
      }, [] as SurveyAnswerInput[])
      .filter(Boolean);

    if (submissionId) {
      submitSurveySubmissionComplete({
        variables: {
          submissionId,
          answers: answersValues,
        },
        updater: (store, mutationData) => {
          if (
            mutationData.completeSurveySubmission?.__typename !==
            "SurveySubmissionCompleted"
          ) {
            toast({
              variant: "error",
              body: t("common.errorToast"),
            });
            return;
          }

          onSurveyCompleted(submissionId);
        },
      });
    } else {
      submitSurveySubmissionCreate({
        variables: {
          surveyId: data.nativeSurvey.id,
          answers: answersValues,
          status: submissionStatus,
          metadata,
        },
        updater: (store, mutationData) => {
          if (
            mutationData.createSurveySubmission?.__typename !==
            "SurveySubmissionCreated"
          ) {
            toast({
              variant: "error",
              body: t("common.errorToast"),
            });
            return;
          }

          onSurveyCompleted(mutationData.createSurveySubmission.id);
        },
      });
    }
  };

  return {
    status: isLoadingSurveySuccessful ? "success" : "error",
    data: {
      ...state,
      isSubmitting: creatingSubmission || completingSubmission,
    },
    effects: {
      handleSubValueChange,
      handleTextChange,
      handleOptionChosen,
      navigateToPreviousQuestion,
      navigateToNextQuestion,
      submit,
    },
  };
}

function getInitialState({
  nativeSurvey,
  formInitialState,
}: {
  nativeSurvey: IQueryData["nativeSurvey"];
  formInitialState?: NativeSurveyFormInitialState;
}): NativeSurveyState {
  const currentQuestionIndex = formInitialState?.currentQuestionIndex ?? 0;
  const questions =
    nativeSurvey.__typename === "NativeSurvey" ? nativeSurvey.questions : [];

  const answers: NativeSurveyAnswers =
    formInitialState?.initialAnswers ?? new Map();

  const currentQuestionData = getQuestionGroupData({
    nativeSurvey,
    questionIndex: currentQuestionIndex,
    answers,
  });
  const numberOfQuestions = new Map(
    questions.map((question) => [question.groupId ?? question.id, question]),
  ).size;

  return {
    answers,
    currentAnswer: answers.get(currentQuestionData.id) ?? null,
    currentQuestionIndex,
    currentQuestionData,
    lastActionType: null,
    numberOfQuestions,
    isFirstQuestion: currentQuestionIndex === 0,
    isLastQuestion: currentQuestionIndex === numberOfQuestions - 1,
  };
}

function nativeSurveyReducer(
  state: NativeSurveyState,
  action: NativeSurveyActions,
): NativeSurveyState {
  const {
    type,
    answer,
    payload: { nativeSurvey },
    onAnswerConfirmed,
  } = action;
  let questionIndex: number = state.currentQuestionIndex;

  if (nativeSurvey.__typename !== "NativeSurvey") {
    return state;
  }

  let newAnswers: NativeSurveyAnswers = new Map(state.answers);

  switch (type) {
    case NativeSurveyActionKind.ANSWER_UPDATED:
      if (answer) {
        // 1. Remove all previous answers of the same question group
        newAnswers = removePreviousQuestionGroupAnswer({
          nativeSurvey,
          questionId: answer.id,
          answers: newAnswers,
        });

        // 2. Add/update the answer
        newAnswers.set(answer.id, answer);
      }
      break;
    case NativeSurveyActionKind.PREVIOUS_QUESTION:
      questionIndex = Math.max(state.currentQuestionIndex - 1, 0);
      break;
    case NativeSurveyActionKind.NEXT_QUESTION:
      onAnswerConfirmed?.();
      questionIndex = Math.min(
        state.currentQuestionIndex + 1,
        state.numberOfQuestions - 1,
      );
      break;
  }

  const currentQuestionData = getQuestionGroupData({
    nativeSurvey,
    questionIndex,
    answers: state.answers,
  });

  // Initialise the answer if it's not already set
  if (!newAnswers.has(currentQuestionData.id)) {
    newAnswers = removePreviousQuestionGroupAnswer({
      nativeSurvey,
      questionId: currentQuestionData.id,
      answers: newAnswers,
    });

    newAnswers.set(currentQuestionData.id, {
      id: currentQuestionData.id,
      chosenOption: {
        label: "",
        score: 0,
        value: currentQuestionData.preSelectedOptionValue ?? "",
      },
      type: currentQuestionData.type,
    });
  }

  return {
    answers: newAnswers,
    currentAnswer: newAnswers.get(currentQuestionData.id) ?? null,
    currentQuestionIndex: questionIndex,
    currentQuestionData,
    lastActionType: type,
    numberOfQuestions: state.numberOfQuestions,
    isLastQuestion: state.numberOfQuestions === questionIndex + 1,
    isFirstQuestion: questionIndex === 0,
  };
}

const getQuestionGroupData = ({
  nativeSurvey,
  questionIndex,
  answers,
}: {
  nativeSurvey: IQueryData["nativeSurvey"];
  questionIndex: number;
  answers: NativeSurveyAnswers;
}): NativeSurveyState["currentQuestionData"] => {
  const allQuestions =
    nativeSurvey.__typename === "NativeSurvey" ? nativeSurvey.questions : [];

  // Group questions per id or groupId, taking in account previous answers
  const groupedQuestions = allQuestions.reduce(
    (acc, question) => {
      if (acc.length > questionIndex + 1) {
        return acc;
      }

      const isTheQuestionForIndex =
        question.logic.length === 0 ||
        question.logic.every(({ requiredAnswers }) => {
          return requiredAnswers.every(({ questionId, answerIds }) => {
            const answer = answers.get(questionId);

            return answer && answerIds.includes(answer.chosenOption.value);
          });
        });

      if (isTheQuestionForIndex) {
        acc.push(question);
      }

      return acc;
    },
    [] as NativeSurveyState["currentQuestionData"][],
  );

  return groupedQuestions[questionIndex];
};

const removePreviousQuestionGroupAnswer = ({
  nativeSurvey,
  questionId,
  answers,
}: {
  nativeSurvey: IQueryData["nativeSurvey"];
  questionId: string;
  answers: NativeSurveyAnswers;
}): NativeSurveyAnswers => {
  const allQuestions =
    nativeSurvey.__typename === "NativeSurvey" ? nativeSurvey.questions : [];
  const newAnswers: NativeSurveyAnswers = new Map(answers);

  const answerQuestionGroupId = allQuestions.find(
    (question) => question.id === questionId,
  )?.groupId;

  if (answerQuestionGroupId) {
    allQuestions.forEach((question) => {
      if (question.groupId === answerQuestionGroupId) {
        newAnswers.delete(question.id);
      }
    });
  }

  return newAnswers;
};

const navigateToQuestion = (
  dispatch,
  type: NativeSurveyActionKind,
  data: IQueryData,
  onAnswerConfirmed?: () => void,
) => {
  delay(DEBOUNCE_TEXT_TIME).then(() =>
    dispatch({
      type,
      payload: data,
      onAnswerConfirmed,
    }),
  );
};
