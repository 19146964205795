import ctl from "@netlify/classnames-template-literals";

export const footerNoticeWrapper = ctl(`
  flex
  gap-3
  mb-8
  p-4
  rounded-lg
  bg-neutral-50
  text-sm
  text-neutral-600
`);
